.Navbar {
    height: 55px;
    background-color: #162e60;
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 0 1rem;
}

.Navbar span {
    color: #fff !important;
}

.Navbar a {
    color: white !important;
}

@media(min-width:0) and (max-width:1000.9px) {
    .Navbar {
        display: none;
    }
}