.about_me {
    position: relative;
    height: 90vh;
}

.about_me img {
    width: 100%;
    height: 90vh;
    object-fit: cover !important;
}

.text_content {
    background: rgba(0, 0, 0, 0.559);
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.text_content h1 {
    color: white;
    font-family: 'Montserrat-SemiBold', sans-serif;
    font-size: 55px;
    text-align: center;
}

@media(min-width:0) and (max-width:750.9px) {
    .text_content h1 {
        font-size: 25px;
    }
}

@media(min-width:751px) and (max-width:950.9px) {
    .text_content h1 {
        font-size: 35px;
    }
}