@import url(https://fonts.googleapis.com/css?family=Raleway);

.snip1584 {
  font-family: 'Monospace', sans-serif;
  position: relative;
  display: inline-block;
  overflow: hidden;
  margin: 10px;
  min-width: 230px;
  max-width: 315px;
  width: 100%;
  color: #ffffff;
  font-size: 16px;
  border: 2px soid red;
  text-align: left;
}

.snip1584 * {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: all 0.25s ease;
  transition: all 0.25s ease;
}

.snip1584:before {
  position: absolute;
  top: 10px;
  bottom: 10px;
  left: 10px;
  right: 10px;
  top: 100%;
  content: '';
  background-color: rgba(51, 51, 51, 0.9);
  -webkit-transition: all 0.25s ease;
  transition: all 0.25s ease;
  -webkit-transition-delay: 0.25s;
  transition-delay: 0.25s;
}

.snip1584 img {
  vertical-align: top;
  max-width: 100%;
  backface-visibility: hidden;
}

.snip1584 figcaption {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.snip1584 h3,
.snip1584 h5 {
  margin: 0;
  opacity: 0;
  letter-spacing: 1px;
}

.snip1584 h3 {
  -webkit-transform: translateY(-100%);
  transform: translateY(-100%);
  text-transform: uppercase;
  font-weight: 400;
  -webkit-transition-delay: 0.05s;
  transition-delay: 0.05s;
  margin-bottom: 5px;
}

.snip1584 h5 {
  font-weight: normal;
  background-color: #ae895d;
  padding: 3px 10px;
  -webkit-transform: translateY(-100%);
  transform: translateY(-100%);
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
}

.snip1584 a {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
}

.snip1584:hover:before,
.snip1584.hover:before {
  top: 10px;
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
}

.snip1584:hover h3,
.snip1584.hover h3,
.snip1584:hover h5,
.snip1584.hover h5 {
  -webkit-transform: translateY(0);
  transform: translateY(0);
  opacity: 1;
}

.snip1584:hover h3,
.snip1584.hover h3 {
  -webkit-transition-delay: 0.3s;
  transition-delay: 0.3s;
}

.snip1584:hover h5,
.snip1584.hover h5 {
  -webkit-transition-delay: 0.2s;
  transition-delay: 0.2s;
}

.app {
  width: 1200px;
  margin: auto;
  font-family: 'Raleway';
  font-size: 24px;
}


/* -------------------------- */
@import url('https://fonts.googleapis.com/css?family=Playfair+Display:700|IBM+Plex+Sans:500&display=swap');

:root {
  --color-primary: #6b7a8f;
  --color-secondary: #101118;
  --color-accent: #1d1f2f;
  --color-focus: #6d64f7;
  --base-duration: 600ms;
  --base-ease: cubic-bezier(0.25, 0.46, 0.45, 0.84);
}

#app {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  overflow-x: hidden;
  width: 100%;
}

h1,
h2,
h3 {
  font-family: 'Playfair Display', serif;
}

.visuallyhidden {
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px;
  overflow: hidden;
  position: absolute !important;
  white-space: nowrap;
  width: 1px;
}

.icon {
  fill: var(--color-primary);
  width: 100%;
}

.btn {
  background-color: var(--color-primary);
  border: none;
  border-radius: 0.125rem;
  color: white;
  cursor: pointer;
  font-family: inherit;
  font-size: inherit;
  padding: 1rem 2.5rem 1.125rem;
}

.btn:focus {
  outline-color: var(--color-focus);
  outline-offset: 2px;
  outline-style: solid;
  outline-width: 3px;
}

.btn:active {
  transform: translateY(1px);
}

.slider__controls {
  display: flex;
  justify-content: space-between;
  font-family: 'Inter', sans-serif;
  font-weight: 500;
  top: calc(100% + 1rem);
  width: 90%;
  margin: 0 5%;
  box-sizing: border-box;
}

.slider__controls div {
  display: flex;
  align-items: center;
}

.slider__controls .btn {
  --size: 3rem;
  align-items: center;
  background-color: transparent;
  border: 3px solid transparent;
  border-radius: 100%;
  display: flex;
  height: var(--size);
  padding: 0;
  width: var(--size);
}

.slider__controls .btn:focus {
  /* border-color: var(--color-focus); */
  outline: none;
}

.slider__controls .btn--previous>* {
  transform: rotate(180deg);
}

.slider {
  --slide-size: 70vmin;
  --slide-margin: 4vmin;
  height: 100px;
  margin: 0 auto;
  position: relative;
  width: 200px;
}

.slider__wrapper {
  padding: 0;
  display: flex;
  margin: 0;
  position: absolute;
  transition: transform var(--base-duration) cubic-bezier(0.25, 1, 0.35, 1);
}

.slide {
  align-items: center;
  color: black;
  display: flex;
  flex: 1;
  flex-direction: row;
  height: 100px;
  justify-content: center;
  margin: 0 var(--slide-margin);
  opacity: 1;
  position: relative;
  text-align: center;
  transition: opacity calc(var(--base-duration) / 2) var(--base-ease), transform calc(var(--base-duration) / 2) var(--base-ease);
  z-index: 1;
  background-color: rgb(244 248 251);
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.05);
}

.body__ {
  margin: 3rem 0;

}

.slide--previous:hover,
.slide--next:hover {
  opacity: 1;
}

.slide--previous {
  cursor: w-resize;
}

.slide--previous:hover {
  transform: translateX(2%);
}

.slide--next {
  cursor: e-resize;
}

.slide--next:hover {
  transform: translateX(-2%);
}

.slide--current {
  --x: 0;
  --y: 0;
  --d: 50;
  opacity: 1;
  pointer-events: auto;
  user-select: auto;
}

.slide__image-wrapper {
  border-radius: 1%;
  height: 100px;
  left: 0%;
  overflow: hidden;
  top: 0%;
  transition: transform calc(var(--base-duration) / 4) var(--base-ease);
  width: 225px;
  padding-left: 1rem;
}

.slide__image {
  object-fit: contain;

  --d: 20;
  height: 100px;
  left: -5%;
  object-fit: cover;
  opacity: 0;
  pointer-events: none;
  /* position: absolute; */
  top: -5%;
  transition: opacity var(--base-duration) var(--base-ease), transform var(--base-duration) var(--base-ease);
  user-select: none;
}

.slide__headline {
  text-align: left;
  font-weight: 600;
  position: relative;
  font-family: 'Montserrat-Regular', sans-serif;
  font-size: 15px;
  line-height: 19px;
}

.slide__content {
  --d: 60;
  opacity: 1;
  padding: 4vmin;
  position: relative;
  transition: transform var(--base-duration) var(--base-ease);
  /* visibility: hidden; */
}

.slide--current .slide__content {
  animation: fade-in calc(var(--base-duration) / 2) var(--base-ease) forwards;
}

.slide__content>*+* {
  margin-top: 2rem;
}

@keyframes fade-in {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@media(min-width:0) and (max-width:750.9px) {
  .body__ {
    display: none;
  }
}