.News_Page {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 32px 30px;
}

.News_Page h1 {
    width: 100%;
}
.muddat{
    display: flex;
    justify-content: space-between;
    background-color: rgb(0, 153, 255);
    padding:0 10px;
}
.muddat2{
    display: flex;
    justify-content: space-between;
    background-color: red;
    padding:0 10px;
}
.muddat3{
    display: flex;
    justify-content: space-between;
    background-color: rgba(255, 255, 0, 0.534);
    padding:0 10px;
}
.muddat4{
    display: flex;
    justify-content: space-between;
    background-color: green;
    padding:0 10px;
}
.muddat p{
    font-weight: bolder;
    font-size: 14px;
}
.farmon {
    width: 28%;
    margin-bottom: 1rem;
    border-radius: 6px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.farmon h2{
    margin: 0;
    font-size: 12px;
    padding: 10px;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}
