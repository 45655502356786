.Footer {
    background: #162e60;
    padding: 20px;
    bottom: 0;
}
.Footer p{
    text-align: center;
    color: white;
    font-size: 20px;
}
.Footer a{
    text-align: center;
    color: white;
    font-size: 20px;  
}
@media(min-width:0) and (max-width:750.9){
    .Footer {
        padding: 10px;
    }
    .Footer p{
        font-size: 15px;
    }   
}