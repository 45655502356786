.body {
    padding: 15px 5%;
}

.Direktor_foto {
    margin-top: 3rem;
}

.breadcrumb {
    margin: 1rem 0;
}

.title {
    font-size: 30px;
    line-height: 36px;
    margin-bottom: 1.5rem;
}

.block {
    background-color: #162e60;
    display: flex;
    flex-wrap: wrap;
    border-radius: 10px;
}

.closeOpen {
    width: 100%;
}

.block img {
    margin: 1rem;
    box-sizing: border-box;
    width: 20%;
    border-radius: 10px;
}

.block h2 {
    font-family: 'Montserrat-SemiBold', sans-serif;
    font-size: 18px;
    color: #96bdfc;
    font-weight: 400;
    margin-bottom: 10px;
    margin-top: 30px;
}

.block .main {
    margin-left: 1rem;

    /*  flex-direction: column;
    justify-content: center; */
}

.block h1 {
    margin-bottom: 10px;
    margin-top: 0;
    font-family: 'Montserrat-Medium', sans-serif;
    font-size: 24px;
    color: #fff;
    font-weight: 700;
    font-size: 22px;
    line-height: 26px;
}

.contact {
    display: flex;
    justify-content: space-between;
    margin-top: 15px;
}

.contact a {
    text-decoration: none;
    font-size: 16px;
    line-height: 24px;
    color: #fff;
}

.main h4 {
    line-height: 20px;
    font-size: 17px;
    font-weight: 400;
    color: #fff;
    margin-top: 15px;
}

.main button {
    cursor: pointer;
    background-color: transparent;
    width: max-content;
    border: 2px solid #fff;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 8px 24px;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    color: #fff;
    margin-top: 1rem;
}

.closeOpen {
    margin-left: 1rem;
    margin-bottom: 2rem;
    color: #fff;
}

.closeOpen h1 {
    font-weight: 700;
    font-size: 24px;
    line-height: 34px;
    color: #fff;
    font-family: 'Montserrat-Regular', sans-serif;
    /* text-align: center; */
}

.lavhalar1 img:hover {
    transform: scale(1.1) !important;
    transition: 1.1s !important;
}
@media(min-width:0) and (max-width:450.9px){
    .body {
        padding: 15px 15px;
    }  
    .block img {
        width: 60%;
    }
    .block {
        justify-content: center;
        padding: 1rem;
    }
    .contact a {
        width: 100%;
        text-decoration: none;
        font-size: 16px;
        line-height: 24px;
        color: #fff;
    }
}
@media(min-width:451px) and (max-width:750.9px){
    .body {
        padding: 15px 15px;
    }  
    .block img {
        width: 40%;
    }
    .block {
        justify-content: center;
        padding: 1rem;
    }
    .contact a {
        width: 100%;
        text-decoration: none;
        font-size: 16px;
        line-height: 24px;
        color: #fff;
    }
}