.Regions {
    padding: 32px 30px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.Regions h1 {
    width: 100%;
    margin-bottom: 3rem;
}

.cards {
    border: 1px solid rgb(196, 184, 184);
    padding: 24px;
    width: 27%;
    border-radius: 4px;
    margin-top: 14px;
}

.cards img {
    width: 100%;
    height: 300px;
}

.wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}

.cards h1 {
    font-size: 20px;
}

.wrapper :nth-child(1) {
    width: 35%;
    

}
.wrapper p{
    text-align: center;
    font-size: 15px;
}
.wrapper :nth-child(2) {
    width: 60%;
}

.wrapper :nth-child(3) {
    width: 40%;
}

.wrapper :nth-child(4) {
    width: 55%;
    
}