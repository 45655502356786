.Contact {
    /* border: 2px solid red; */
    padding: 32px 30px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;

}

.title {
    font-size: 28px;
    font-weight: 700;
    width: 100%;
    font-family: sans-serif;
    margin: 0;
}

.Contact h2 {
    margin: 8px 0;
    font-weight: 500;
    font-size: 16px;
    color: #27292f;
    font-family: sans-serif;
}

.Contact p {
    margin: 0px 0;
}

.main_block {
    width: 25%;
}

.Contact a {
    text-decoration: none;
    color: black;
}

.social_net {
    display: flex;
    justify-content: center;
}

.social_net a {
    font-size: 20px;
    margin-right: 14px;
}

@media(min-width:0) and (max-width:850.9px) {
    .Contact {
        padding: 32px 5px;
    }

    .main_block {
        width: 100%;
        margin-top: 1rem;
        text-align: center;
    }

    .Tel {
        margin-top: 1rem;
    }

    .Tel,
    .faks,
    .pochta,
    .links {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-around;
    }

    .title {
        font-size: 25px;
        text-align: center;
    }
}