.ant-btn-default {
    background-color: transparent !important;
    font-size: 15px;
    line-height: 20px;
   font-weight: 500;
   border: none !important;
   margin: 0 2rem;
}
.ant-table-content {
  overflow: auto !important;
}
.alo_error{
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px;
  background-color: blue;
}
.blue_error{
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px;
  background-color: rgb(160, 160, 6);
}
.ant-table-tbody > tr > td{
  text-align: center !important;
}
.ant-table-thead > tr > th{
  text-align: center !important;
}
.red_error{
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px;
  background-color: red;
}
:where(.css-dev-only-do-not-override-yp8pcc).ant-image .ant-image-img{
    height: 100%;
}
.App{
  overflow: hidden;
}
.ant-image.css-dev-only-do-not-override-yp8pcc{
  grid-area: one;
}
.ant-table-thead > tr > th {
  border: 1px solid rgb(228, 222, 222);
  }