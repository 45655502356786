.PrejectName {
    margin-top: 1rem;
    padding: 1rem 3rem;
  }
  .wrapper{
    display: grid;
    grid-template-columns: 33% 33% 33%;
  }
  .PageameNavlink {
    color: white;
    text-decoration: none;
  }
  .PageameNavlink:hover {
    color: red;
  }
  .box {
    width: 400px;
    height: 300px;
    position: relative;
    text-align: center;
    color: white;
    overflow: hidden;
    border-radius: 8px;
    margin: 15px 0;
  }
  .box:hover {
    box-shadow: 2px 2px 6px 2px rgb(151, 144, 144);
    transition: box-shadow 0.2s 0.5s linear;
  }
  
  .main-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .box-content {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    padding: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    z-index: 3;
    transform: translateY(75px);
    transition: transform 0.2s 0.2s linear;
  }
  
  .item {
    font: bold 20px/24px Arial, sans-serif;
    margin-bottom: 12px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    text-shadow: 0 0 1px #000;
  }
  
  .description {
    font: 16px/24px Arial, sans-serif;
    margin-bottom: 24px;
    height: 72px;
    overflow: hidden;
  }
  
  .box:after {
    content: " ";
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: #000;
    opacity: 0.2;
    transition: opacity 0.2s linear;
  }
  .box:hover:after {
    opacity: 0.7;
  }
  
  .interactive {
    display: flex;
  }
  
  .interactive .item svg {
    flex-shrink: 0;
    margin-right: 10px;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    fill: #fff;
  }
  
  .interactive .item {
    display: flex;
    align-items: center;
    width: 50%;
    font-size: 14px;
  }
  
  .interactive .item:last-child {
    justify-content: flex-end;
  }
  
  .box:before {
    content: " ";
    border-radius: 8px;
    position: absolute;
    top: 20px;
    left: 20px;
    bottom: 20px;
    right: 20px;
    border: 1px solid #fff;
    z-index: 2;
    pointer-events: none;
    transition: transform 0.2s linear, opacity 0.2s linear;
    transform: scale(0.7);
    opacity: 0;
  }
  
  .show-animate {
    opacity: 0;
    transform: translateY(20px);
    transition: opacity 0.2s 0.2s linear;
  }
  
  .box:hover .show-animate {
    opacity: 1;
    transform: translateY(0);
    transition: transform 1s linear;
  }
  
  .box:hover .box-content {
    transform: translateY(0);
  }
  
  .box:hover:before {
    transform: scale(1);
    opacity: 1;
  }
  .show-item {
    transition: transform 0.2s linear, opacity 0.2s linear;
    opacity: 0;
  }
  .show-item:first-child {
    transform: translateX(-20px);
    margin-left: 40px;
  }
  
  .show-item:last-child {
    transform: translateX(20px);
    margin-right: 40px;
  }
  .box:hover .show-item {
    transform: translateX(0);
    opacity: 1;
    transition: transform 0.2s 0.4s linear, opacity 0.2s 0.4s linear;
  }
  @media(min-width:0) and (max-width:1230.9px){
    .wrapper{
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
    }
    .box{
      margin: 1rem;
    }
  }