.News_Page {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding: 32px 30px;
}

.News_Page h1 {
  width: 100%;
}

.snip1527 {
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
  color: #fff;
  float: left;
  font-family: Lato, Arial, sans-serif;
  font-size: 16px;
  margin: 10px 1%;
  max-width: 310px;
  min-width: 250px;
  overflow: hidden;
  position: relative;
  text-align: left;
  width: 23%;
}

.snip1527 * {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: all 0.25s ease;
  transition: all 0.25s ease;
}

.snip1527 img {
  max-width: 100%;
  vertical-align: top;
  position: relative;
  height: 400px;
  object-fit: cover;
}

.snip1527 figcaption {
  padding: 25px 20px;
  position: absolute;
  bottom: 0;
  width: 100%;
  z-index: 1;
}

.snip1527 figcaption:before {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  content: "";
  background: -moz-linear-gradient(90deg,
      #700877 0,
      #ff2759 100%,
      #ff2759 100%);
  background: -webkit-linear-gradient(90deg,
      #700877 0,
      #ff2759 100%,
      #ff2759 100%);
  background: linear-gradient(90deg, #700877 0, #ff2759 100%, #ff2759 100%);
  opacity: 0.8;
  z-index: -1;
}

.snip1527 .date {
  background-color: #fff;
  border-radius: 50%;
  color: #700877;
  font-size: 8px;
  font-weight: 700;
  min-height: 50px;
  min-width: 50px;
  padding: 10px 0;
  position: absolute;
  right: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  top: -25px;
}

.snip1527 .date span {
  display: block;
  line-height: 14px;
}

.snip1527 .date .month {
  font-size: 11px;
}

.snip1527 h3,
.snip1527 p {
  margin: 0;
  padding: 0;
}

.snip1527 h3 {
  display: inline-block;
  font-weight: 700;
  letter-spacing: -0.4px;
  margin-bottom: 5px;
  font-size: 15px;
}

.snip1527 p {
  font-size: 0.6em;
  line-height: 1.6em;
  margin-bottom: 0;
}

.snip1527 a {
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  position: absolute;
  z-index: 1;
}

.snip1527.hover img,
.snip1527:hover img {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

img {
  border-radius: 5px;
}

@media(min-width:0) and (max-width:650.9px) {
  .News_Page h1 {
    width: 100%;
    font-size: 25px;
    text-align: center;
  }

  .snip1527 {
    width: 100%;
  }
}

@media(min-width:651px) and (max-width:1000.9px) {
  .News_Page h1 {
    text-align: center;
  }

  .News_Page {
    justify-content: space-around;
    padding: 32px 10px;
  }

  .snip1527 {
    width: 40%;
  }

}