.Wrapper {
    padding: 32px 30px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

}
.Wrapper h1{
    width: 100%;
    margin-bottom: 3rem;
}
.Wrapper p{
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    padding: 3rem;
    font-size: 22px;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}