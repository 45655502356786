.Paxtachilik {
    padding: 32px 30px;

}

.project {
    border: 2px solid #162e60;
    padding: 1rem;
    background-color: #162e60;
    border-radius: 10px;
    margin-top: 3rem;
}

.project p {
    font-size: 20px;
    text-align: center;
    line-height: 30px;
    color: white;
}

.Paxtachilik h1,
.Paxtachilik h2 {
    font-size: 27px;
}

.Paxtachilik h2 {
    margin-top: 3rem;
}

.Paxtachilik a {
    width: 100%;
    text-decoration: none;
    line-height: 40px;
    font-size: 22px;
}